import { AssetType, CustomizedType } from "./types/enums";

export const DELEGATE_PATH = "UserDelegates";
export const DELEGATE_INVITES_PATH = "UserDelegateInvites";

export function prefixFinanceName(
  financeType:
    | AssetType.CashAndBanking
    | AssetType.BankOrInstitution
    | AssetType.TraditionalInvestments
    | AssetType.OtherInvestment
    | AssetType.Cryptocurrency,
  name: string
) {
  return `${financeType}_${name}`;
}
export function checkAndPrefixFinanceName(type: string, name: string): string {
  switch (type) {
    case AssetType.CashAndBanking:
    case AssetType.BankOrInstitution:
    case AssetType.TraditionalInvestments:
    case AssetType.OtherInvestment:
    case AssetType.Cryptocurrency:
      return prefixFinanceName(type, name);
    default:
      return name;
  }
}
type IndexedAssetsPathInput = AssetType | "PortfolioTransaction";
export enum PermissionCategory {
  Finance = "Finance",
  Insurance = AssetType.Insurance,
  Property = AssetType.Property,
  Art = AssetType.Art,
  Wine = AssetType.WineAndSpirits,
  OtherCollectables = AssetType.OtherCollectables,
  Belonging = AssetType.Belonging,
}
export function getPermissionCategory(
  input: IndexedAssetsPathInput
): PermissionCategory {
  switch (input) {
    case "PortfolioTransaction":
    case AssetType.CashAndBanking:
    case AssetType.BankOrInstitution:
    case AssetType.TraditionalInvestments:
    case AssetType.OtherInvestment:
    case AssetType.Cryptocurrency:
      return PermissionCategory.Finance;

    case AssetType.Insurance:
      return PermissionCategory.Insurance;

    case AssetType.Property:
      return PermissionCategory.Property;
    case AssetType.Art:
      return PermissionCategory.Art;
    case AssetType.OtherCollectables:
      return PermissionCategory.OtherCollectables;
    case AssetType.Belonging:
      return PermissionCategory.Belonging;
    case AssetType.WineAndSpirits:
    case AssetType.WinePurchases:
      return PermissionCategory.Wine;
  }
}

export function getIndexedAssetsPath(asset: IndexedAssetsPathInput) {
  return `Indexed/${getPermissionCategory(asset)}/${asset}`;
}
export function getIndexedActivitiesPath(asset: IndexedAssetsPathInput) {
  return `Indexed/${getPermissionCategory(asset)}/Activities`;
}
export function getAssetUserDataPath(
  userId: string,
  asset: IndexedAssetsPathInput
) {
  return `UserData/${userId}/${getPermissionCategory(asset)}`;
}
export function getRelationsUserDataPath(userId: string) {
  return `UserData/${userId}/Relations`;
}
export function getCustomizedTypeCollectionPath(
  userId: string,
  type: CustomizedType
) {
  switch (type) {
    // Cash and Banking
    case CustomizedType.BankOrInstitution:
      return getAssetUserDataPath(userId, AssetType.CashAndBanking);
    case CustomizedType.InsuranceCompany:
    case CustomizedType.InsurancePolicyName:
      return getAssetUserDataPath(userId, AssetType.Insurance);
    // Art
    case CustomizedType.ArtArtistName:
      return getAssetUserDataPath(userId, AssetType.Art);
    // WineAndSpirits
    case CustomizedType.WineAndSpiritsProducer:
      return getAssetUserDataPath(userId, AssetType.WineAndSpirits);
    // OtherCollectables
    case CustomizedType.OtherCollectablesType:
    case CustomizedType.OtherCollectablesBrand:
    case CustomizedType.OtherCollectablesModel:
      return getAssetUserDataPath(userId, AssetType.OtherCollectables);
    // Belonging
    case CustomizedType.BelongingType:
    case CustomizedType.BelongingBrand:
      return getAssetUserDataPath(userId, AssetType.Belonging);
    // Address Book
    case CustomizedType.ContactType:
      return `UserData/${userId}/Contact`;
  }
}

export function getProfilePath(userId: string) {
  return `UserProfiles/${userId}`;
}

export function getSubscriptionInfoPath(userId: string) {
  return `UserSubscriptionInfo/${userId}`;
}

export function getIntegrationsPath(userId: string) {
  return `UserData/${userId}/Integrations`;
}

export function getPlaidDocPath(userId: string) {
  return `${getIntegrationsPath(userId)}/Plaid`;
}

export function getBillingHistoryPath(userId: string) {
  return `UserBillingHistory/${userId}/Current`;
}

export function getAddressBookPath(userId: string) {
  return `UserData/${userId}/AddressBook`;
}

export function getGroupPath(userId: string) {
  return `UserData/${userId}/Groups`;
}

export function getDelegatePath(userId: string) {
  return `${DELEGATE_PATH}/${userId}`;
}

export function getSummaryPath(
  userId: string,
  assetType: IndexedAssetsPathInput
) {
  return `${getAssetUserDataPath(
    userId,
    assetType
  )}/${checkAndPrefixFinanceName(assetType, "Summary")}`;
}

const TIME_TICKER_STATUS_COLLECTION_NAME = "TimeTickerTaskStatus";
const TIME_TICKER_COLLECTION_NAME = "TimeTickerPrices";
const CRONJOB_STATUS_COLLECTION_NAME = "CronjobStatus";
const USER_DATA_DELETION_RECORD = "UserDataDeletionRecord";

export function getUserDataDeletionRecordPath(userId: string) {
  return `${USER_DATA_DELETION_RECORD}/${userId}`;
}

export function getTTStatusPath() {
  return TIME_TICKER_STATUS_COLLECTION_NAME;
}

export function getTTPricesPath() {
  return TIME_TICKER_COLLECTION_NAME;
}

export function getTTCronjobDocPath() {
  return `${CRONJOB_STATUS_COLLECTION_NAME}/TimeTicker`;
}

export function getTUCCronjobDocPath(task: string) {
  return `${CRONJOB_STATUS_COLLECTION_NAME}/UserTaskUpdateCoordinator/${task}/Status`;
}

export function getUserFAUpdatePath(userId: string, date: string) {
  return `/UserData/${userId}/FinancialAssetUpdateStatus/${date}`;
}

export function getFinancialBalanceSheetPath(userId: string, date: string) {
  return `User/${userId}/FinanceBalanceSheet/${date}`;
}

export function getUncategorizedAttachmentPath(userId: string) {
  return `UserData/${userId}/Attachment`;
}

export function getBackupCodesPath(userId: string) {
  return `UserData/${userId}/BackupCodes/BackupCodes`;
}

export function getStorageLimitPath(userId: string) {
  return `UserStorageLimit/${userId}`;
}
