import { mutate } from 'swr'

import type { Database } from 'core/remodel/database'
import { ExportRow } from 'core/remodel/database/exportHandler'
import { type Art } from 'core/remodel/types/arts'
import { type Belonging } from 'core/remodel/types/belongings'
import { Account } from 'core/remodel/types/cashAndBanking'
import { type Cash } from 'core/remodel/types/cashAndBanking/cash'
import {
  AssetV2,
  AttachmentKind,
  IVAttachmentFileSaltFieldKey,
  LocationType,
  type Attachment,
  type BalanceSheet,
  type Currency
} from 'core/remodel/types/common'
import { Cryptocurrency } from 'core/remodel/types/cryptocurrencies'
import { AssetType, CustomizedType } from 'core/remodel/types/enums'
import { Insurance } from 'core/remodel/types/insurance'
import { OtherInvestment } from 'core/remodel/types/otherInvestments'
import { type Property } from 'core/remodel/types/properties'
import { type LocationInfo } from 'core/remodel/types/relations/locationInfo'
import { Portfolio } from 'core/remodel/types/traditionalInvestments'
import { Removal, RemovalReason } from 'core/remodel/types/wineAndSprits'
import { propertyQuery } from '@/api/PropertyService'
import type { AssetItem, DocumentFile, Folder, PropertyAssetsItem } from '@/types/common'
import { delay } from '@/utils/delay'
import { ImageSizes } from '@/utils/imageTools'
import { isOwnerDetail } from '@/utils/predicate'
import { SearchItemProps } from '@/components/GlobalSearch'

export const commonQuery = {
  attachmentUrl: 'attachment-url',
  thumbnailUrl: 'thumbnail-url',
  attachmentIV: 'attachment-iv',
  associatedFiles: 'associated-files',
  locationInfo: 'location-info',
  assetList: 'asset-list',
  liabilityAssetList: 'liability-asset-list',
  nameAndMainImage: 'name-and-main-image',
  global: 'global-summary',
  oneSchemaToken: 'one-schema-token',
  getAllAttachments: 'get-all-attachments',
  assetNames: 'assetNames',
  exchangeRates: 'exchange-rates',
  targetExchangeRates: 'target-exchange-rates',
  globalSearch: 'global-search',
  mainImageUrlIv: 'main-image-url-iv',
  storageLimit: 'storage-limit'
} as const

export function fetchGlobalSearchResult(database: Database) {
  return async ([_key, keyword]: [typeof commonQuery.globalSearch, string]) => {
    const resp = await database.getAssetsByGlobalSearch(keyword)
    return resp.map(
      (item): SearchItemProps => ({
        id: item.fireId,
        assetType: item.category as AssetType,
        subType: item.subType,
        name: item.name ?? ''
      })
    )
  }
}

export function fetchExchangeRates(database: Database) {
  return async ([_key, from, to]: [typeof commonQuery.exchangeRates, Currency, Currency]) => {
    return await database.ExRate.getExchangeRate(from, to)
  }
}

export function fetchTargetExchangeRates(database: Database) {
  return async ([_key, currency]: [typeof commonQuery.targetExchangeRates, Currency]) => {
    return await database.ExRate.getToTargetExchangeRates(currency)
  }
}

export function fetchOneSchemaToken(database: Database) {
  return async ([_key]: [typeof commonQuery.oneSchemaToken]) => {
    return await database.getOneSchemaToken()
  }
}

export function fetchAttachmentUrl(database: Database) {
  return async ([_key, ...params]: [typeof commonQuery.attachmentUrl, string, string]) => {
    return await database.Attachments.getUrl(...params)
  }
}

export function fetchThumbnailUrl(database: Database) {
  return async ([_key, assetId, attachmentKey, imageSize]: [
    typeof commonQuery.thumbnailUrl,
    string,
    string,
    ImageSizes
  ]) => {
    try {
      const key = `${attachmentKey}_${imageSize}`
      const url = await database.Attachments.getUrl(assetId, key)
      return url
    } catch (e) {
      console.log('Failed to get thumbnail', e)
      return await database.Attachments.getUrl(assetId, attachmentKey)
    }
  }
}

export function fetchAttachmentIv(database: Database) {
  return async ([_key, assetType, assetId, attachmentKey]: [
    typeof commonQuery.attachmentIV,
    AssetType,
    string,
    string
  ]) => {
    const asset = (await database.getRawAssetById(assetType, assetId)) as AssetV2
    return asset.attachments?.find((attachment) => attachment.key === attachmentKey)?.[IVAttachmentFileSaltFieldKey]
  }
}

export function fetchMainImageUrlAndIv(database: Database) {
  return async ([_key, assetType, assetId]: [typeof commonQuery.mainImageUrlIv, AssetType, string]) => {
    let url
    const { attachments, mainImage } = (await database.getRawAssetById(assetType, assetId)) as AssetV2
    if (mainImage) {
      try {
        url = await database.Attachments.getUrl(assetId, `${mainImage}_${ImageSizes.Small}`)
      } catch (e) {
        url = await database.Attachments.getUrl(assetId, mainImage)
      }
    }
    const iv = attachments?.find((attachment) => attachment.key === mainImage)?.[IVAttachmentFileSaltFieldKey]
    return { url, iv }
  }
}

export interface AssociatedFile extends Attachment {
  type: AttachmentKind
  url: string
  thumbnails?: { small: string; large: string }
}

export function fetchAssociatedFiles(database: Database) {
  return async ([_key, id, attachments]: [typeof commonQuery.associatedFiles, string, Attachment[]]) => {
    const files = await Promise.all(
      attachments.map(async (attach): Promise<AssociatedFile> => {
        const url = await database.Attachments.getUrl(id, attach.key)
        let thumbnails = { small: url, large: url }
        if (attach.type === AttachmentKind.AssetImage) {
          try {
            thumbnails.small = await database.Attachments.getUrl(id, `${attach.key}_${ImageSizes.Small}`)
            thumbnails.large = await database.Attachments.getUrl(id, `${attach.key}_${ImageSizes.Large}`)
          } catch (e) {
            console.log('Failed to get image thumbnail', e)
          }
        }

        return { ...attach, url, thumbnails }
      })
    )
    const assetImages = files.filter((file) => file.type === AttachmentKind.AssetImage)
    const primaryDetails = files.filter((file) => file.type === AttachmentKind.PrimaryDetails)
    const acquisition = files.filter((file) => file.type === AttachmentKind.Acquisition)
    const attributes = files.filter((file) => file.type === AttachmentKind.Attributes)
    const location = files.filter((file) => file.type === AttachmentKind.Location)
    const ownership = files.filter((file) => file.type === AttachmentKind.Ownership)
    return { assetImages, primaryDetails, acquisition, attributes, location, ownership }
  }
}

export const typeQuery = {
  artistName: 'art-artist-name',
  belongingType: 'belonging-type',
  belongingBrand: 'belonging-brand',
  institution: 'bank-or-institution',
  insuranceCompany: 'insurance-company',
  insurancePolicyName: 'insurance-policy-name',
  otherType: 'other-collectables-type',
  otherBrand: 'other-collectables-brand',
  otherModel: 'other-collectables-model',
  wineProducer: 'wine-and-spirits-producer',
  contactType: 'contact-type'
} as const

/* Custom Type */
export function fetchCustomTypes(database: Database) {
  return async ([_key, typeName]: [(typeof typeQuery)[keyof typeof typeQuery], CustomizedType]) => {
    return await database.listCustomizedType(typeName)
  }
}

export async function addCustomType(database: Database, type: CustomizedType, name: string) {
  const key = {
    [CustomizedType.ArtArtistName]: typeQuery.artistName,
    [CustomizedType.BelongingType]: typeQuery.belongingType,
    [CustomizedType.BelongingBrand]: typeQuery.belongingBrand,
    [CustomizedType.BankOrInstitution]: typeQuery.institution,
    [CustomizedType.InsuranceCompany]: typeQuery.insuranceCompany,
    [CustomizedType.InsurancePolicyName]: typeQuery.insurancePolicyName,
    [CustomizedType.OtherCollectablesType]: typeQuery.otherType,
    [CustomizedType.OtherCollectablesBrand]: typeQuery.otherBrand,
    [CustomizedType.OtherCollectablesModel]: typeQuery.otherModel,
    [CustomizedType.WineAndSpiritsProducer]: typeQuery.wineProducer,
    [CustomizedType.ContactType]: typeQuery.contactType
  }[type]
  await database.addCustomizedType(type, name)
  await delay()
  mutate([key, type])
  return name
}

/* Location */
export function fetchLocation(database: Database) {
  return async ([_key, type, id]: [typeof commonQuery.locationInfo, LocationType, string]) => {
    switch (type) {
      case LocationType.MyProperty:
        return await database.property.getById(id)
      case LocationType.Address:
        return await database.Account.getContact(id)
    }
  }
}

export async function relocateAssets(
  database: Database,
  selected: PropertyAssetsItem[],
  newLocation: LocationInfo,
  keyword: string,
  propertyId: string,
  params: any,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: selected.length }

  const isNewAddress = newLocation.locationType === LocationType.NewAddress
  if (isNewAddress) newLocation.locationType = LocationType.Address

  for (const { id, assetType, wineId, purchaseId } of selected) {
    switch (assetType) {
      case AssetType.Belonging: {
        const belongings = await database.belonging.getById(id)
        const oldLocation = belongings.location
        if (oldLocation.locationId === newLocation.locationId) continue

        await database.belonging.update({ ...belongings, location: newLocation })
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.Art: {
        const arts = await database.art.getById(id)
        const oldLocation = arts.location
        if (oldLocation.locationId === newLocation.locationId) continue

        await database.art.update({ ...arts, location: newLocation })
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.OtherCollectables: {
        const otherCollectables = await database.otherCollectable.getById(id)
        const oldLocation = otherCollectables.location
        if (oldLocation.locationId === newLocation.locationId) continue

        await database.otherCollectable.update({ ...otherCollectables, location: newLocation })
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.WineAndSpirits: {
        // id: bottleId
        await database.wine.relocateBottle(wineId!, purchaseId!, [id], newLocation)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      default: {
        console.log(`relocateAssets: assetType ${assetType} not supported`)
        break
      }
    }
  }

  await delay()
  const refreshKeys = [
    [propertyQuery.info, propertyId],
    [propertyQuery.assets, params],
    [propertyQuery.assetsQuery, keyword, propertyId, params]
  ]
  refreshKeys.forEach((key) => mutate(key))
}

/* Asset */
export async function deleteAssets(
  database: Database,
  selected: PropertyAssetsItem[],
  keyword: string,
  propertyId: string,
  params: any,
  callback?: (percentage: number) => void
) {
  let progress: Record<'current' | 'total', number> = { current: 0, total: selected.length }
  for (const { id, assetType, wineId, purchaseId } of selected) {
    switch (assetType) {
      case AssetType.Belonging: {
        await database.belonging.delete(id)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.Art: {
        await database.art.delete(id)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.OtherCollectables: {
        await database.otherCollectable.delete(id)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      case AssetType.WineAndSpirits: {
        const removal: Removal = {
          reason: RemovalReason.DeleteAndExclude,
          date: new Date(),
          notes: ''
        }
        await database.wine.removeBottle(wineId!, purchaseId!, [id], removal)
        progress = { current: progress.current + 1, total: progress.total }
        callback && callback(Math.round((progress.current / progress.total) * 100))
        break
      }
      default: {
        console.log(`deleteAssets: assetType ${assetType} not supported`)
        break
      }
    }
  }

  await delay()
  const refreshKeys = [
    [propertyQuery.info, propertyId],
    [propertyQuery.assets, params],
    [propertyQuery.assetsQuery, keyword, propertyId, params]
  ]
  refreshKeys.forEach((key) => mutate(key))
}

export function fetchSupportLiabilityAssets(database: Database) {
  return async ([_key]: [typeof commonQuery.liabilityAssetList]) => {
    const art = await database.art.getAll()
    const wine = await database.wine.getAllWines()
    const otherCollectable = await database.otherCollectable.getAll()
    const belonging = await database.belonging.getAll()
    const property = await database.property.getAll()

    return [...art, ...wine, ...otherCollectable, ...belonging, ...property]
  }
}

export function fetchAllAssetsOptions(database: Database) {
  return async ([_key]: [typeof commonQuery.assetList]) => {
    const allAssetsMap = {
      [AssetType.Art]: database.art.getAll(),
      [AssetType.WineAndSpirits]: database.wine.getAllWines(),
      [AssetType.OtherCollectables]: database.otherCollectable.getAll(),
      [AssetType.Belonging]: database.belonging.getAll(),
      [AssetType.Property]: database.property.getAll(),
      [AssetType.Insurance]: database.insurance.getAll(),
      [AssetType.Cryptocurrency]: database.cryptocurrency.getAll(),
      [AssetType.TraditionalInvestments]: database.traditionalInvestment.getAllPortfolio(),
      [AssetType.OtherInvestment]: database.otherInvestment.getAll(),
      [AssetType.CashAndBanking]: database.cashAndBanking.getAllInstitution()
    }

    const assets: { label: string; value: { assetType: AssetType; assetId: string } }[][] = await Promise.all(
      Object.keys(allAssetsMap).map(async (assetType): Promise<any> => {
        switch (assetType) {
          case AssetType.Art:
          case AssetType.WineAndSpirits:
          case AssetType.OtherCollectables:
          case AssetType.Belonging:
          case AssetType.Property:
          case AssetType.Insurance:
          case AssetType.Cryptocurrency:
          case AssetType.TraditionalInvestments:
          case AssetType.OtherInvestment: {
            const items = await allAssetsMap[assetType]
            return items
              .map((asset) => ({ label: asset.name, value: { assetType: assetType, assetId: asset.id } }))
              .flat()
          }
          case AssetType.CashAndBanking: {
            // HACK: [todo] return institution_account instead of institution
            const items = await allAssetsMap[assetType]
            return items
              .map((asset) => ({ label: asset.name, value: { assetType: assetType, assetId: asset.id } }))
              .flat()
          }
        }
      })
    )
    return assets.flat()
  }
}

export function fetchContactAsset(database: Database) {
  return async ([_key, contactId, AssetId, assetType, subType]: [
    typeof commonQuery.nameAndMainImage,
    string,
    string,
    AssetType,
    Account.Type | undefined
  ]) => {
    const contactTag: string[] = []
    switch (assetType) {
      case AssetType.CashAndBanking: {
        if (subType === undefined) return undefined
        const { name, mainImage } = await database.cashAndBanking.getAccountById(AssetId, subType)
        return { name, mainImage, contactTag }
      }
      case AssetType.TraditionalInvestments: {
        const { name, mainImage, ownership, beneficiary } =
          await database.traditionalInvestment.getPortfolioById(AssetId)
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.OtherInvestment: {
        const { name, mainImage, contactIds, ownership, beneficiary } = await database.otherInvestment.getById(AssetId)
        contactIds?.find((item) => item === contactId) && contactTag.push('Contact')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.Cryptocurrency: {
        const { name, mainImage, ownership, beneficiary } = await database.cryptocurrency.getById(AssetId)
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.Insurance: {
        const { name, mainImage, beneficiary, insured, brokerId, specialistId } =
          await database.insurance.getById(AssetId)
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        insured?.find((item) => item.targetId === contactId) && contactTag.push('Insured')
        brokerId === contactId && contactTag.push('Broker')
        specialistId === contactId && contactTag.push('Specialist')
        return { name, mainImage, contactTag }
      }
      case AssetType.Property: {
        const { name, mainImage, detail } = await database.property.getById(AssetId)
        if (isOwnerDetail(detail)) {
          detail.acquisition?.sellerId === contactId && contactTag.push('Seller')
          detail.ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
          detail.beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        }
        return { name, mainImage, contactTag }
      }
      case AssetType.Art: {
        const { name, mainImage, location, acquisition, ownership, beneficiary } = await database.art.getById(AssetId)
        location.locationId === contactId && contactTag.push('Location')
        acquisition?.sellerId === contactId && contactTag.push('Seller')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.OtherCollectables: {
        const { name, mainImage, location, acquisition, ownership, beneficiary } =
          await database.otherCollectable.getById(AssetId)
        location.locationId === contactId && contactTag.push('Location')
        acquisition?.sellerId === contactId && contactTag.push('Seller')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.Belonging: {
        const { name, mainImage, location, acquisition, ownership, beneficiary } =
          await database.belonging.getById(AssetId)
        location.locationId === contactId && contactTag.push('Location')
        acquisition?.sellerId === contactId && contactTag.push('Seller')
        ownership?.shareholder.find((item) => item.contactId === contactId) && contactTag.push('Shareholder')
        beneficiary?.find((item) => item.contactId === contactId) && contactTag.push('Beneficiary')
        return { name, mainImage, contactTag }
      }
      case AssetType.WineAndSpirits: {
        const { vintage, name, mainImage } = await database.wine.getWineById(AssetId)
        //TODO wine unit is type or purchase for display contact id is seller ..etc
        return { name: `${vintage} ${name}`, mainImage, contactTag }
      }
      case AssetType.WinePurchases:
      case AssetType.BankOrInstitution:
        return undefined
    }
  }
}

export function fetchNames(database: Database) {
  return async ([_key, items]: [typeof commonQuery.assetNames, AssetItem[]]) => {
    const itemsName = Promise.all(
      items.map(async (item) => {
        switch (item.assetType) {
          case AssetType.CashAndBanking: {
            if (item.subType === undefined) return { name: 'cash & banking', link: `/finances/accounts` } //TODO, no subType
            const { name } = await database.cashAndBanking.getAccountById(item.assetId, item.subType)
            return { name: name, link: `/finances/accounts/info/?id=${item.assetId}&type=${item.subType}` }
          }
          case AssetType.TraditionalInvestments: {
            const { name } = await database.traditionalInvestment.getPortfolioById(item.assetId)
            return { name: name, link: `/finances/tradit-invest/info/?id=${item.assetId}` }
          }
          case AssetType.OtherInvestment: {
            const { name } = await database.otherInvestment.getById(item.assetId)
            return { name: name, link: `/finances/other-invest/info/?id=${item.assetId}` }
          }
          case AssetType.Cryptocurrency: {
            const { name } = await database.cryptocurrency.getById(item.assetId)
            return { name: name, link: `/finances/crypto/` }
          }
          case AssetType.Insurance: {
            const { name } = await database.insurance.getById(item.assetId)
            return { name: name, link: `/finances/insurance/info/?id=${item.assetId}` }
          }
          case AssetType.Property: {
            const { name } = await database.property.getById(item.assetId)
            return { name: name, link: `/properties/summary/info/?id=${item.assetId}` }
          }
          case AssetType.Art: {
            const { name } = await database.art.getById(item.assetId)
            return { name: name, link: `/collectables/art/info/?id=${item.assetId}` }
          }
          case AssetType.OtherCollectables: {
            const { name } = await database.otherCollectable.getById(item.assetId)
            return { name: name, link: `/collectables/other/info/?id=${item.assetId}` }
          }
          case AssetType.Belonging: {
            const { name } = await database.belonging.getById(item.assetId)
            return { name: name, link: `/belongings/summary/info/?id=${item.assetId}` }
          }
          case AssetType.WineAndSpirits: {
            const { vintage, name } = await database.wine.getWineById(item.assetId)
            return { name: `${vintage} ${name}`, link: `/collectables/wine/info/?id=${item.assetId}` }
          }
          case AssetType.WinePurchases:
            return { name: 'Wine Purchase', link: '/' } //TODO, no Purchases
          case AssetType.BankOrInstitution:
            return { name: 'Bank Or Institution', link: '/' } //TODO, no BankOrInstitution
        }
      })
    )
    return itemsName
  }
}

export function fetchPropertyAssetsNames(database: Database) {
  return async ([_key, items]: [typeof commonQuery.assetNames, PropertyAssetsItem[]]) => {
    const itemsName = Promise.all(
      items.map(async (item) => {
        switch (item.assetType) {
          case AssetType.Art: {
            const { name } = await database.art.getById(item.id)
            return { name: name, link: `/collectables/art/info/?id=${item.id}` }
          }
          case AssetType.OtherCollectables: {
            const { name } = await database.otherCollectable.getById(item.id)
            return { name: name, link: `/collectables/other/info/?id=${item.id}` }
          }
          case AssetType.Belonging: {
            const { name } = await database.belonging.getById(item.id)
            return { name: name, link: `/belongings/summary/info/?id=${item.id}` }
          }
          case AssetType.WineAndSpirits: {
            const { vintage, name } = await database.wine.getWineById(item.id)
            return { name: `${vintage} ${name}`, link: `/collectables/wine/info/?id=${item.id}` }
          }
          case AssetType.WinePurchases:
            return { name: 'Wine Purchase', link: '/' } //TODO, no Purchases
          default:
            return { name: '', link: '' }
        }
      })
    )
    return itemsName
  }
}

// Global Dashboard
export function fetchGlobalDashboard(database: Database) {
  return async ([_key, currency]: [typeof commonQuery.global, Currency?]) => {
    const globalDashboard = await database.getGlobalDashboard(currency)
    const { myFinances, myProperties, myCollectables, myBelongings, valueDistribution, ...global } = globalDashboard

    const reGenerateBalanceSheet = <T extends BalanceSheet>({ assets, liabilities, netValue: _, ...other }: T): T => {
      const netValue: BalanceSheet['netValue'] = { currency: assets.currency, value: assets.value - liabilities.value }
      return { assets, liabilities, netValue, ...other } as T
    }

    const result: typeof globalDashboard = {
      myFinances: reGenerateBalanceSheet(myFinances),
      myProperties: reGenerateBalanceSheet(myProperties),
      myCollectables: reGenerateBalanceSheet(myCollectables),
      myBelongings: reGenerateBalanceSheet(myBelongings),
      valueDistribution,
      ...reGenerateBalanceSheet(global)
    }
    return result
  }
}

/* Attachment */
export function fetchStorageLimit(database: Database) {
  return async ([_key]: [typeof commonQuery.storageLimit]) => {
    const resp = await database.Attachments.getStorageLimit()
    return resp
  }
}

export async function getMetadata(database: Database, assetId: string, attachmentKey: string) {
  const metadata = await database.Attachments.getMeta(assetId, attachmentKey)
  let size = metadata.size
  if (metadata.contentType?.startsWith('image/')) {
    try {
      for (const suffix of Object.values(ImageSizes)) {
        const thumbnailMeta = await database.Attachments.getMeta(assetId, `${attachmentKey}_${suffix}`)
        size += thumbnailMeta.size
      }
    } catch (e) {
      console.log(e)
    }
  }
  return { ...metadata, size }
}

export async function uploadFile(
  database: Database,
  assetId: string,
  attachmentKey: string,
  data: File,
  iv: Uint8Array
) {
  const result = await database.Attachments.upload(assetId, attachmentKey, data, data.type, iv)

  await delay()
  mutate([commonQuery.storageLimit])
  return result
}

export async function removeMetadata(
  database: Database,
  assetId: string,
  assetType: AssetType | undefined,
  attachmentKey: string,
  params: any
) {
  let assetInfo
  switch (assetType) {
    case AssetType.Art:
      assetInfo = await database.art.getById(assetId)
      break
    case AssetType.Property:
      assetInfo = await database.property.getById(assetId)
      break
    case AssetType.Belonging:
      assetInfo = await database.belonging.getById(assetId)
      break
    case AssetType.WineAndSpirits:
      assetInfo = await database.wine.getWineById(assetId)
      break
    case AssetType.OtherCollectables:
      assetInfo = await database.otherCollectable.getById(assetId)
      break
    case AssetType.CashAndBanking:
      assetInfo = await database.cashAndBanking.getAccountById<Cash, Account.Type.Cash>(assetId, Account.Type.Cash)
      break
    case AssetType.TraditionalInvestments: {
      assetInfo = await database.traditionalInvestment.getPortfolioById(assetId)
      break
    }
    case AssetType.OtherInvestment: {
      assetInfo = await database.otherInvestment.getById(assetId)
      break
    }
    case AssetType.Cryptocurrency: {
      assetInfo = await database.cryptocurrency.getById(assetId)
      break
    }
    case AssetType.Insurance: {
      assetInfo = await database.insurance.getById(assetId)
      break
    }
  }

  if (!assetInfo) return
  const attachments = assetInfo.attachments?.filter((attachment) => attachment.key !== attachmentKey) ?? []
  const mainImage =
    assetInfo.mainImage === attachmentKey
      ? attachments.filter((attach) => attach.type === AttachmentKind.AssetImage)[0]?.key
      : assetInfo.mainImage

  switch (assetType) {
    case AssetType.Art:
      await database.art.update({ ...(assetInfo as Art), attachments, mainImage })
      break
    case AssetType.Property:
      await database.property.update({ ...(assetInfo as Property), attachments, mainImage })
      break
    case AssetType.Belonging:
      await database.belonging.update({ ...(assetInfo as Belonging), attachments, mainImage })
      break
    case AssetType.WineAndSpirits:
      assetInfo = await database.wine.getWineById(assetId)
      const firstPurchase = assetInfo.purchases[0]
      await database.wine.update(
        assetId,
        firstPurchase.id,
        firstPurchase,
        assetInfo.personalRefNo,
        attachments,
        mainImage
      )
      break
    case AssetType.OtherCollectables:
      await database.otherCollectable.update({ ...(assetInfo as Belonging), attachments, mainImage })
      break
    case AssetType.CashAndBanking:
      await database.cashAndBanking.updateAccount({
        ...(assetInfo as Cash),
        attachments
      })
      break
    case AssetType.TraditionalInvestments: {
      await database.traditionalInvestment.update({
        ...(assetInfo as Portfolio),
        attachments,
        mainImage
      })
      break
    }
    case AssetType.OtherInvestment: {
      await database.otherInvestment.update({
        ...(assetInfo as OtherInvestment),
        attachments,
        mainImage
      })
      break
    }
    case AssetType.Cryptocurrency: {
      await database.cryptocurrency.update({
        ...(assetInfo as Cryptocurrency),
        attachments,
        mainImage
      })
      break
    }
    case AssetType.Insurance: {
      await database.insurance.update({
        ...(assetInfo as Insurance),
        attachments,
        mainImage
      })
      break
    }
  }
  await delay()
  mutate([commonQuery.getAllAttachments, params])
}

export async function deleteFile(
  database: Database,
  assetId: string,
  assetType: AssetType | undefined,
  attachmentKey: string,
  params: any
) {
  removeMetadata(database, assetId, assetType, attachmentKey, params)
  const { contentType = '' } = await database.Attachments.getMeta(assetId, attachmentKey)
  if (contentType.startsWith('image/')) {
    // remove thumbnails
    Promise.all(
      Object.values(ImageSizes).map((size) =>
        database.Attachments.delete(assetId, `${attachmentKey}_${size}`).catch((e) => console.log(e))
      )
    )
  }
  await database.Attachments.delete(assetId, attachmentKey).catch((e) => console.log(e))
}

export async function renameVaultFile(
  database: Database,
  assetId: string,
  assetType: AssetType | undefined,
  attachmentKey: string,
  newName: string,
  params: any
) {
  let assetInfo

  switch (assetType) {
    case AssetType.Art:
      assetInfo = await database.art.getById(assetId)
      await database.art.update({
        ...assetInfo,
        attachments: assetInfo.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    case AssetType.Property:
      assetInfo = await database.property.getById(assetId)
      await database.property.update({
        ...assetInfo,
        attachments: assetInfo.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    case AssetType.Belonging:
      assetInfo = await database.belonging.getById(assetId)
      await database.belonging.update({
        ...assetInfo,
        attachments: assetInfo.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
      break
    case AssetType.WineAndSpirits:
      assetInfo = await database.wine.getWineById(assetId)
      const firstPurchase = assetInfo.purchases[0]
      const attachments =
        assetInfo.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        })) ?? []
      await database.wine.update(
        assetInfo.id,
        firstPurchase.id,
        firstPurchase,
        assetInfo.personalRefNo,
        attachments,
        assetInfo.mainImage
      )
      break
    case AssetType.OtherCollectables:
      assetInfo = await database.otherCollectable.getById(assetId)
      await database.otherCollectable.update({
        ...assetInfo,
        attachments: assetInfo.attachments?.map((attach) => ({
          ...attach,
          name: attach.key === attachmentKey ? newName : attach.name
        }))
      })
  }
  await delay()
  mutate([commonQuery.getAllAttachments, params])
}

export async function updateAttachment(
  database: Database,
  assetId: string,
  assetType: AssetType,
  mainImage: string | undefined,
  newAttachment: Attachment[]
) {
  let assetInfo
  switch (assetType) {
    case AssetType.Art:
      assetInfo = await database.art.getById(assetId)
      await database.art.update({
        ...assetInfo,
        mainImage,
        attachments: newAttachment ?? []
      })
      break
    case AssetType.Property:
      assetInfo = await database.property.getById(assetId)
      await database.property.update({
        ...assetInfo,
        mainImage,
        attachments: newAttachment
      })
      break
    case AssetType.Belonging:
      assetInfo = await database.belonging.getById(assetId)
      await database.belonging.update({
        ...assetInfo,
        mainImage,
        attachments: newAttachment
      })
      break
    case AssetType.WineAndSpirits:
      assetInfo = await database.wine.getWineById(assetId)
      const firstPurchase = assetInfo.purchases[0]
      await database.wine.update(
        assetId,
        firstPurchase.id,
        firstPurchase,
        assetInfo.personalRefNo,
        newAttachment,
        mainImage
      )
      break
    case AssetType.OtherCollectables:
      assetInfo = await database.otherCollectable.getById(assetId)
      await database.otherCollectable.update({
        ...assetInfo,
        mainImage,
        attachments: newAttachment
      })
    case AssetType.CashAndBanking:
      assetInfo = await database.cashAndBanking.getAccountById<Cash, Account.Type.Cash>(assetId, Account.Type.Cash)
      await database.cashAndBanking.updateAccount({
        ...assetInfo,
        attachments: newAttachment
      })
  }
}

// Document Vault
export function getAllAttachments(database: Database) {
  return async ([_key, query]: [typeof commonQuery.getAllAttachments, Record<string, any>]) => {
    function mapAssetsToFolder(assets: any[], root: any) {
      const result: Folder[] = assets.map((item) => ({
        id: item.name,
        name: item.name,
        root,
        type: 'Folder'
      }))
      return result
    }

    function mapAttachmentToFile(attachments: any[], root: any) {
      const result: DocumentFile[] = attachments.map((attachment) => ({
        key: attachment.key,
        id: attachment.id,
        assetId: attachment.assetId,
        name: attachment.name,
        type: 'File',
        mimeType: attachment.contentType,
        root,
        assetLink: attachment.assetLink,
        uploaded: attachment.timeCreated,
        assetType: attachment.assetType,
        tabInfo: attachment.tabInfo,
        isFavorite: false,
        [IVAttachmentFileSaltFieldKey]: attachment[IVAttachmentFileSaltFieldKey]
      }))
      return result
    }

    const [Belongings, Arts, Wines, Others, Properties, Banks, TradiInvests, OtherInvests, Cryptos, Insurances] =
      await Promise.all([
        database.getAssetsWithQuery<Belonging>(AssetType.Belonging, query),
        database.getAssetsWithQuery<Art>(AssetType.Art, query),
        database.wine.getAllWines(),
        database.getAssetsWithQuery<Belonging>(AssetType.OtherCollectables, query),
        database.getAssetsWithQuery<Property>(AssetType.Property, query),
        database.cashAndBanking.getAllInstitution(),
        database.traditionalInvestment.getAllPortfolio(),
        database.otherInvestment.getAll(),
        database.cryptocurrency.getAll(),
        database.insurance.getAll()
      ])

    const BelongingsFolder = mapAssetsToFolder(Belongings, 'my-belongings')
    const ArtsFolder = mapAssetsToFolder(Arts, 'arts')
    const WinesFolder = mapAssetsToFolder(Wines, 'wine-and-spirits')
    const OthersFolder = mapAssetsToFolder(Others, 'other-collectables')
    const PropertiesFolder = mapAssetsToFolder(Properties, 'my-properties')
    const BanksFolder = mapAssetsToFolder(Banks, 'cash-and-banking')
    const TradiInvestsFolder = mapAssetsToFolder(TradiInvests, 'traditional-investments')
    const OtherInvestsFolder = mapAssetsToFolder(OtherInvests, 'other-investments')
    const CryptosFolder = mapAssetsToFolder(Cryptos, 'cryptocurrencies')
    const InsurancesFolder = mapAssetsToFolder(Insurances, 'insurances')

    //Files
    const BelongingsFiles: DocumentFile[][] = []
    for (const Belonging of Belongings) {
      const allAttachMeta = await Promise.all(
        (Belonging.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(Belonging.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: Belonging.id,
              name: attachment.name,
              assetType: AssetType.Belonging,
              tabInfo: attachment.type,
              assetLink: `/belongings/summary/info/?id=${Belonging.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: Belonging.id,
              name: attachment.name,
              assetType: AssetType.Belonging,
              tabInfo: attachment.type,
              assetLink: `/belongings/summary/info/?id=${Belonging.id}`
            }
          }
        })
      )

      const BelongingsFile = mapAttachmentToFile(allAttachMeta, Belonging.name)
      BelongingsFiles.push(BelongingsFile)
    }

    const ArtsFiles: DocumentFile[][] = []
    for (const Art of Arts) {
      const allAttachMeta = await Promise.all(
        (Art.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(Art.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: Art.id,
              name: attachment.name,
              assetType: AssetType.Art,
              tabInfo: attachment.type,
              assetLink: `/collectables/art/info/?id=${Art.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: Art.id,
              name: attachment.name,
              assetType: AssetType.Art,
              tabInfo: attachment.type,
              assetLink: `/collectables/art/info/?id=${Art.id}`
            }
          }
        })
      )
      const ArtsFile = mapAttachmentToFile(allAttachMeta, Art.name)
      ArtsFiles.push(ArtsFile)
    }

    const WinesFiles: DocumentFile[][] = []
    for (const Wine of Wines) {
      const allAttachMeta = await Promise.all(
        (Wine.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(Wine.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: Wine.id,
              name: attachment.name,
              assetType: AssetType.WineAndSpirits,
              tabInfo: attachment.type,
              assetLink: `/collectables/wine/info/?id=${Wine.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: Wine.id,
              name: attachment.name,
              assetType: AssetType.WineAndSpirits,
              tabInfo: attachment.type,
              assetLink: `/collectables/wine/info/?id=${Wine.id}`
            }
          }
        })
      )
      const WinesFile = mapAttachmentToFile(allAttachMeta, Wine.name)
      WinesFiles.push(WinesFile)
    }

    const OthersFiles: DocumentFile[][] = []
    for (const Other of Others) {
      const allAttachMeta = await Promise.all(
        (Other.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(Other.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: Other.id,
              name: attachment.name,
              assetType: AssetType.OtherCollectables,
              tabInfo: attachment.type,
              assetLink: `/collectables/other/info/?id=${Other.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: Other.id,
              name: attachment.name,
              assetType: AssetType.OtherCollectables,
              tabInfo: attachment.type,
              assetLink: `/collectables/other/info/?id=${Other.id}`
            }
          }
        })
      )

      const OthersFile = mapAttachmentToFile(allAttachMeta, Other.name)
      OthersFiles.push(OthersFile)
    }

    const PropertiesFiles: DocumentFile[][] = []
    for (const Property of Properties) {
      const allAttachMeta = await Promise.all(
        (Property.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(Property.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: Property.id,
              name: attachment.name,
              assetType: AssetType.Property,
              tabInfo: attachment.type,
              assetLink: `/properties/summary/info/?id=${Property.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: Property.id,
              name: attachment.name,
              assetType: AssetType.Property,
              tabInfo: attachment.type,
              assetLink: `/properties/summary/info/?id=${Property.id}`
            }
          }
        })
      )
      const PropertiesFile = mapAttachmentToFile(allAttachMeta, Property.name)
      PropertiesFiles.push(PropertiesFile)
    }

    const AccountsFolder: Folder[][] = []
    const BanksFiles: DocumentFile[][] = []
    for (const bank of Banks) {
      const accounts = await database.cashAndBanking.getAccountsByIds(Object.keys(bank.accounts))
      AccountsFolder.push(mapAssetsToFolder(accounts, bank.name))
      for (const account of accounts) {
        const allAttachMeta = await Promise.all(
          (account.attachments ?? []).map(async (attachment) => {
            try {
              const attachMeta = await database.Attachments.getMeta(account.id, attachment.key)
              return {
                ...attachMeta,
                key: attachment.key,
                assetId: account.id,
                name: attachment.name,
                assetType: AssetType.CashAndBanking,
                tabInfo: attachment.type,
                assetLink: `/finances/accounts/info/?id=${account.id}`
              }
            } catch (error) {
              return {
                contentType: 'corrupted file',
                key: attachment.key,
                assetId: account.id,
                name: attachment.name,
                assetType: AssetType.CashAndBanking,
                tabInfo: attachment.type,
                assetLink: `/finances/accounts/info/?id=${account.id}`
              }
            }
          })
        )
        const accountFile = mapAttachmentToFile(allAttachMeta, account.name)
        BanksFiles.push(accountFile)
      }
    }

    const TradiInvestsFiles: DocumentFile[][] = []
    for (const TradiInvest of TradiInvests) {
      const allAttachMeta = await Promise.all(
        (TradiInvest.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(TradiInvest.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: TradiInvest.id,
              name: attachment.name,
              assetType: AssetType.TraditionalInvestments,
              tabInfo: attachment.type,
              assetLink: `/finances/tradit-invest/info/?id=${TradiInvest.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: TradiInvest.id,
              name: attachment.name,
              assetType: AssetType.TraditionalInvestments,
              tabInfo: attachment.type,
              assetLink: `/finances/tradit-invest/info/?id=${TradiInvest.id}`
            }
          }
        })
      )
      const TradiInvestsFile = mapAttachmentToFile(allAttachMeta, TradiInvest.name)
      TradiInvestsFiles.push(TradiInvestsFile)
    }

    const OtherInvestsFiles: DocumentFile[][] = []
    for (const OtherInvest of OtherInvests) {
      const allAttachMeta = await Promise.all(
        (OtherInvest.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(OtherInvest.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: OtherInvest.id,
              name: attachment.name,
              assetType: AssetType.OtherInvestment,
              tabInfo: attachment.type,
              assetLink: `/finances/other-invest/info/?id=${OtherInvest.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: OtherInvest.id,
              name: attachment.name,
              assetType: AssetType.OtherInvestment,
              tabInfo: attachment.type,
              assetLink: `/finances/other-invest/info/?id=${OtherInvest.id}`
            }
          }
        })
      )
      const OtherInvestsFile = mapAttachmentToFile(allAttachMeta, OtherInvest.name)
      OtherInvestsFiles.push(OtherInvestsFile)
    }

    const InsurancesFiles: DocumentFile[][] = []
    for (const Insurance of Insurances) {
      const allAttachMeta = await Promise.all(
        (Insurance.attachments ?? []).map(async (attachment) => {
          try {
            const attachMeta = await database.Attachments.getMeta(Insurance.id, attachment.key)
            return {
              ...attachMeta,
              key: attachment.key,
              assetId: Insurance.id,
              name: attachment.name,
              assetType: AssetType.Insurance,
              tabInfo: attachment.type,
              assetLink: `/finances/insurance/info/?id=${Insurance.id}`
            }
          } catch (error) {
            return {
              contentType: 'corrupted file',
              key: attachment.key,
              assetId: Insurance.id,
              name: attachment.name,
              assetType: AssetType.Insurance,
              tabInfo: attachment.type,
              assetLink: `/finances/insurance/info/?id=${Insurance.id}`
            }
          }
        })
      )
      const InsurancesFile = mapAttachmentToFile(allAttachMeta, Insurance.name)
      InsurancesFiles.push(InsurancesFile)
    }

    const Folders: Folder[] = [
      ...BelongingsFolder,
      ...ArtsFolder,
      ...WinesFolder,
      ...OthersFolder,
      ...PropertiesFolder,
      ...BanksFolder,
      ...TradiInvestsFolder,
      ...OtherInvestsFolder,
      ...CryptosFolder,
      ...InsurancesFolder,
      ...AccountsFolder.flat()
    ]

    const Documents: DocumentFile[] = [
      ...ArtsFiles.flat(),
      ...WinesFiles.flat(),
      ...OthersFiles.flat(),
      ...BelongingsFiles.flat(),
      ...BanksFiles.flat(),
      ...PropertiesFiles.flat(),
      ...TradiInvestsFiles.flat(),
      ...OtherInvestsFiles.flat(),
      ...InsurancesFiles.flat()
    ]
    return [...Folders, ...Documents]
  }
}

// Export
export async function exportData(database: Database, assetType: AssetType, callback?: (percentage: number) => void) {
  const taskId = database.genAssetId()
  const handler = await database.getExportHandler(assetType, taskId)
  let progress = handler.getProgress()
  const dataRows: ExportRow[] = []

  while (progress.total > progress.finished) {
    const rows = await handler.getNextBatch()
    dataRows.push(...rows)

    const endId = rows.pop()?.id ?? ''
    await handler.markEndId(endId)
    callback && callback(Math.round((progress.finished / progress.total) * 100))
    await delay()
  }
  await handler.deleteExportTask(taskId)

  return dataRows
}

export async function exportSummary(database: Database) {
  return await database.getComparativeNetWorthReport()
}
