import { ReactNode } from 'react'
import { Loader2Icon } from 'lucide-react'
import { IdleTimerProvider } from 'react-idle-timer'

import { promptBeforeIdle, timeout } from '@/constants/expiredTime'
import { useRedirect } from '@/hooks/useRedirect'
import { useRouteGuard } from '@/hooks/useRouteGuard'
import { useAuthStore } from '@/store/authStore'
import AppcuesIdentifier from '@/components/AppcuesIdentifier'
import BaseCurrencySelector from '@/components/BaseCurrencySelector'
import { DataImporterModal } from '@/components/DataImporter'
import DelegateChecker from '@/components/DelegateChecker'
import DialogSessionCountDown from '@/components/DialogSessionCountDown'
import DialogSessionExpired from '@/components/DialogSessionExpired'

interface ProtectedPageProps {
  children: ReactNode
}

export default function ProtectedPage({ children }: ProtectedPageProps) {
  const status = useAuthStore((state) => state.status)
  const logout = useAuthStore((state) => state.logout)

  useRedirect({
    shouldRedirect: status === 'unauthenticated' || status === 'unverified',
    redirectTo: '/auth/login'
  })
  useRouteGuard()

  switch (status) {
    case 'sessionExpired': {
      return (
        <>
          {children}
          <DialogSessionExpired />
        </>
      )
    }
    case 'ready': {
      return (
        <IdleTimerProvider
          timeout={timeout}
          promptBeforeIdle={promptBeforeIdle}
          throttle={500}
          onIdle={async () => await logout(true)}
        >
          {children}
          <DialogSessionCountDown />
          <BaseCurrencySelector />
          <DataImporterModal />
          <AppcuesIdentifier />
          <DelegateChecker />
        </IdleTimerProvider>
      )
    }
    default: {
      return (
        <main className={'flex h-screen w-screen items-center justify-center'}>
          <Loader2Icon className={'h-8 w-8 animate-spin text-primary'} />
        </main>
      )
    }
  }
}
