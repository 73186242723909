import * as Firestore from "firebase/firestore";
import {
  DocumentData,
  CollectionReference,
  DocumentReference,
  FieldValue,
  ICoreFirestore,
  FirestoreError,
  QuerySnapshot,
  StorageReference,
  Transaction,
  TransactionOptions,
  Unsubscribe,
  QueryConstraint,
  UpdateData,
  WhereFilterOp,
  checkAndGetData,
  DocumentSnapshot,
  WithFieldValue,
  FullMetadata,
  UploadResult,
  ICoreAuth,
  PartialWithFieldValue,
  SetOptions,
  AggregateField,
  AggregateQuerySnapshot,
} from "./coreFirebase";
import * as Storage from "firebase/storage";
import { Optional, PathsOfDateField } from "./remodel/types/common";
import {
  Auth,
  User,
  updateCurrentUser,
  updateEmail,
  updateProfile,
} from "firebase/auth";

export class ClientFirestoreImpl implements ICoreFirestore {
  protected readonly firestore: Firestore.Firestore;
  protected readonly storage: Storage.FirebaseStorage;

  constructor(
    firestore: Firestore.Firestore,
    storage: Storage.FirebaseStorage
  ) {
    this.firestore = firestore;
    this.storage = storage;
  }
  isAdmin(): boolean {
    return false;
  }
  collection<T>(path: string): CollectionReference<T> {
    return Firestore.collection(this.firestore, path);
  }
  doc<T>(path: string): DocumentReference<T> {
    return Firestore.doc(this.firestore, path);
  }
  docFromCollection<T>(
    collectionReference: CollectionReference<T>,
    id?: string
  ): DocumentReference<T> {
    return Firestore.doc(
      collectionReference as Firestore.CollectionReference<T>,
      id ?? this.genAssetId()
    );
  }
  ref(url: string): StorageReference {
    return Storage.ref(this.storage, url);
  }
  refExtend(root: StorageReference, ext: string): StorageReference {
    return Storage.ref(root as Storage.StorageReference, ext);
  }

  onSnapshot<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    onNext: (snapshot: DocumentSnapshot<AppModelType, DbModelType>) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    return Firestore.onSnapshot<AppModelType, DbModelType>(
      //#HACK there are two DocumentReference in firestore
      reference as any,
      onNext,
      onError,
      onCompletion
    );
  }

  getDoc<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    reference: DocumentReference<AppModelType, DbModelType>
  ): Promise<DocumentSnapshot<AppModelType, DbModelType>> {
    return Firestore.getDoc<AppModelType, DbModelType>(
      reference as Firestore.DocumentReference<AppModelType, DbModelType>
    );
  }
  setDoc<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: WithFieldValue<AppModelType>
  ): Promise<void> {
    return Firestore.setDoc<AppModelType, DbModelType>(
      reference as Firestore.DocumentReference<AppModelType, DbModelType>,
      data
    );
  }
  setDocWithOption<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: PartialWithFieldValue<AppModelType>,
    options: SetOptions
  ): Promise<void> {
    return Firestore.setDoc<AppModelType, DbModelType>(
      reference as Firestore.DocumentReference<AppModelType, DbModelType>,
      data,
      options
    );
  }
  updateDoc<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    reference: DocumentReference<AppModelType, DbModelType>,
    data: UpdateData<DbModelType>
  ): Promise<void> {
    return Firestore.updateDoc<AppModelType, DbModelType>(
      reference as Firestore.DocumentReference<AppModelType, DbModelType>,
      data
    );
  }
  deleteDoc<AppModelType, DbModelType extends DocumentData>(
    reference: DocumentReference<AppModelType, DbModelType>
  ): Promise<void> {
    return Firestore.deleteDoc<AppModelType, DbModelType>(
      reference as Firestore.DocumentReference<AppModelType, DbModelType>
    );
  }

  private convertConstraints<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    queryConstraints: QueryConstraint[]
  ): (
    | Firestore.QueryLimitConstraint
    | Firestore.QueryOrderByConstraint
    | Firestore.QueryStartAtConstraint
    | Firestore.QueryFieldFilterConstraint
  )[] {
    return queryConstraints.map((constraint) => {
      switch (constraint.kind) {
        case "limit":
          return Firestore.limit(constraint.limit);
        case "orderBy":
          return Firestore.orderBy(
            constraint.fieldPath,
            constraint.directionStr
          );
        case "startAfter":
          return Firestore.startAfter(
            constraint.snapshot as Firestore.DocumentSnapshot<
              AppModelType,
              DbModelType
            >
          );
        case "startAfterFieldValues":
          return Firestore.startAfter(...constraint.fieldValues);
        case "where":
          return Firestore.where(
            constraint.fieldPath,
            constraint.opStr,
            constraint.value
          );
        default:
          throw new Error("Invalid query constraint");
      }
    });
  }
  getDocsFromCollection<
    AppModelType = DocumentData,
    DbModelType extends DocumentData = DocumentData
  >(
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Promise<QuerySnapshot<AppModelType, DbModelType>> {
    const typedConstraints = this.convertConstraints(queryConstraints);
    return Firestore.getDocs<AppModelType, DbModelType>(
      Firestore.query(
        collectionReference as Firestore.CollectionReference<
          AppModelType,
          DbModelType
        >,
        ...typedConstraints
      )
    );
  }
  getCountFromServer<
    AppModelType,
    DbModelType extends DocumentData = DocumentData
  >(
    collectionReference: CollectionReference<AppModelType, DbModelType>,
    ...queryConstraints: QueryConstraint[]
  ): Promise<
    AggregateQuerySnapshot<
      {
        count: AggregateField<number>;
      },
      AppModelType,
      DbModelType
    >
  > {
    const typedConstraints = this.convertConstraints(queryConstraints);
    return Firestore.getCountFromServer<AppModelType, DbModelType>(
      Firestore.query(
        collectionReference as Firestore.CollectionReference<
          AppModelType,
          DbModelType
        >,
        ...typedConstraints
      )
    );
  }

  genAssetId(): string {
    return Firestore.doc(Firestore.collection(this.firestore, " ")).id;
  }
  async getDocsByIdsPure<T>(
    collectionRef: CollectionReference<T>,
    ids: string[]
  ): Promise<T[]> {
    return await Promise.all(
      ids.map((id) =>
        Firestore.getDoc(
          Firestore.doc(collectionRef as Firestore.CollectionReference<T>, id)
        ).then(checkAndGetData)
      )
    );
  }

  runTransaction<T>(
    updateFunction: (transaction: Transaction) => Promise<T>,
    options?: TransactionOptions
  ): Promise<T> {
    return Firestore.runTransaction<T>(this.firestore, updateFunction, options);
  }

  getDownloadURL(ref: StorageReference): Promise<string> {
    return Storage.getDownloadURL(ref as Storage.StorageReference);
  }
  getMetadata(ref: StorageReference): Promise<FullMetadata> {
    return Storage.getMetadata(ref as Storage.StorageReference);
  }
  uploadBytes(
    ref: StorageReference,
    data: Blob | Uint8Array | ArrayBuffer,
    metadata?: unknown
  ): Promise<UploadResult> {
    return Storage.uploadBytes(
      ref as Storage.StorageReference,
      data,
      metadata as Storage.UploadMetadata
    );
  }
  deleteObject(ref: StorageReference): Promise<void> {
    return Storage.deleteObject(ref as Storage.StorageReference);
  }

  checkAndConvertTimestampToDate(input: any): Optional<Date> {
    if (input instanceof Firestore.Timestamp) {
      return input.toDate();
    }
    return undefined;
  }
  convertTimestampToDate<T extends object>(input: any, key: keyof T): void {
    if (input[key] instanceof Firestore.Timestamp) {
      input[key] = input[key].toDate();
    }
  }
  //#NOTE this will change the input object
  convertDateFieldsFromFirestore<T extends object>(
    input: T,
    paths: readonly PathsOfDateField<T>[]
  ) {
    this.convertDateFieldsFromFirestoreNotStrict<T>(input, paths);
  }
  convertDateFieldsFromFirestoreNotStrict<T extends object>(
    input: T,
    paths: readonly string[]
  ) {
    paths.forEach((path) => {
      const parts = path.split(".");
      let current: any = input;
      let idx = 0;
      while (current !== undefined && idx < parts.length) {
        if (current[parts[idx]] instanceof Firestore.Timestamp) {
          current[parts[idx]] = (
            current[parts[idx]] as Firestore.Timestamp
          ).toDate();
          return;
        }
        current = current[parts[idx++]];
      }
    });
  }

  serverTimestamp(): FieldValue {
    return Firestore.serverTimestamp();
  }
  deleteField(): FieldValue {
    return Firestore.deleteField();
  }
  increment(n: number): FieldValue {
    return Firestore.increment(n);
  }

  limit(_n: number): QueryConstraint {
    return <any>{};
  }
  startAfter<AppModelType, DbModelType extends DocumentData>(
    _snapshot: DocumentSnapshot<AppModelType, DbModelType>
  ): QueryConstraint {
    return <any>{};
  }
  startAfterFieldValues(): QueryConstraint {
    return <any>{};
  }
  orderBy(_fieldPath: string, _directionStr?: "desc" | "asc"): QueryConstraint {
    return <any>{};
  }
  where(
    _fieldPath: string,
    _opStr: WhereFilterOp,
    _value: unknown
  ): QueryConstraint {
    return <any>{};
  }
}

export type FromFirestore<T extends object> = {
  [Key in keyof T]: NonNullable<T[Key]> extends Date
    ? Firestore.Timestamp
    : T[Key];
};

export class ClientAuthImpl implements ICoreAuth {
  updateCurrentUser(auth: Auth, user: User | null): Promise<void> {
    return updateCurrentUser(auth, user);
  }
  updateEmail(user: User, newEmail: string): Promise<void> {
    return updateEmail(user, newEmail);
  }
  updateProfile(
    user: User,
    update: {
      displayName?: string | null | undefined;
      photoURL?: string | null | undefined;
    }
  ): Promise<void> {
    return updateProfile(user, update);
  }
}
