import { mutate } from 'swr'

import type { Database } from 'core/remodel/database'
import { Currency } from 'core/remodel/types/common'
import { AssetType } from 'core/remodel/types/enums'
import { Insurance, InsuranceSubType, type Insured } from 'core/remodel/types/insurance'
import { infoPathnameMap } from '@/constants/assets'
import { convertCurrency } from '@/utils/currency'
import { delay } from '@/utils/delay'
import { getFullName } from '@/utils/formatter'
import type { InsuranceValues } from '@/components/form'

type InsuredWithName = Insured & { name: string }

export type InsuranceWithInsuredNames = Omit<Insurance, 'insured'> & {
  insured?: InsuredWithName[]
}

export const insuranceQuery = {
  summary: 'insurance-summary',
  list: 'insurance-list',
  info: 'insurance-info',
  insuredOptions: 'insurance-insured-options'
} as const

export function fetchInsuranceSummary(database: Database) {
  return async ([_key]: [typeof insuranceQuery.summary]) => {
    return await database.insurance.getSyncedSummary()
  }
}

export function fetchInsurances(database: Database) {
  return async ([_key, currency]: [typeof insuranceQuery.list, Currency?]) => {
    const insurancesData = await database.insurance.getAll()
    const targetExRateMap = currency && (await database.ExRate.getToTargetExchangeRates(currency))
    const insurances =
      currency && targetExRateMap ? convertCurrency(insurancesData, targetExRateMap.rates, currency) : insurancesData

    // get names
    for (const insurance of insurances) {
      const insured = insurance.insured ?? []
      const names = await Promise.all(insured.map((insured) => getInsuredName(database, insured)))
      insurance['insured'] = insured.map((insured, index) => ({ ...insured, name: names[index] }))
    }
    return insurances as InsuranceWithInsuredNames[]
  }
}

export function fetchInsurancesByIds(database: Database) {
  return async ([_key, ids]: [typeof insuranceQuery.list, string[]]) => {
    return await database.insurance.getByIds(ids)
  }
}

export function fetchInsurancesByType(database: Database) {
  return async ([_key, subtype]: [typeof insuranceQuery.list, InsuranceSubType]) => {
    const insurances = await database.insurance.getAll()
    return insurances.filter((insurance) => insurance.subtype === subtype)
  }
}

export function fetchInsurance(database: Database) {
  return async ([_key, id]: [typeof insuranceQuery.info, string]) => {
    return await database.insurance.getById(id)
  }
}

const insurable = [AssetType.Property, AssetType.Art, AssetType.OtherCollectables, AssetType.Belonging]

export function fetchInsuredOptions(database: Database) {
  return async ([_key]: [typeof insuranceQuery.insuredOptions]) => {
    const assets = await database.getAssets()
    const insurables = assets.filter((asset) => insurable.includes(asset.assetType))
    return { assets: insurables, options: insurables.map((asset) => ({ label: asset.name, value: asset.id })) }
  }
}

export async function addInsurance(database: Database, id: string, data: InsuranceValues) {
  await database.insurance.add({ ...data, id })
  await delay()
  mutate([typeof insuranceQuery.list])
}

export async function updateInsurance(database: Database, id: string, data: InsuranceValues) {
  const { attachments: newAttach = [] } = data
  const { attachments: oldAttach = [] } = await database.insurance.getById(id)
  await database.insurance.update({ ...data, id })
  Promise.all(
    oldAttach
      .filter(({ key }) => !newAttach.some((attach) => attach.key === key))
      .map(({ key }) => database.Attachments.delete(id, key).catch((e) => console.log(e)))
  )
  await delay()
  mutate([insuranceQuery.info, id])
  mutate([insuranceQuery.list])
}

export async function deleteInsurance(database: Database, id: string) {
  await database.insurance.delete(id)
  await delay()
  mutate([insuranceQuery.list])
}

/* Utils */
async function getInsuredName(database: Database, insured: Insured) {
  switch (insured.targetType) {
    case AssetType.Property:
      const property = await database.property.getById(insured.targetId)
      return property.name
    case AssetType.Art:
      const art = await database.art.getById(insured.targetId)
      return art.name
    case AssetType.OtherCollectables:
      const other = await database.otherCollectable.getById(insured.targetId)
      return other.name
    case AssetType.Belonging:
      const belonging = await database.belonging.getById(insured.targetId)
      return belonging.name
    case 'Person':
      const contact = await database.Account.getContact(insured.targetId)
      return getFullName(contact)
  }
}

export function getInsuredUrl(insured: InsuredWithName) {
  switch (insured.targetType) {
    case AssetType.Property:
    case AssetType.Art:
    case AssetType.OtherCollectables:
    case AssetType.Belonging:
      return infoPathnameMap[insured.targetType]
    case 'Person':
      return '/account/contacts/info/'
  }
}
